import React, { useState } from 'react';
import Axios from 'axios';
import {
    Button, TextField, Typography, Container, Box, CssBaseline, Avatar, IconButton,
    InputAdornment, Card, CardContent, CircularProgress, Snackbar, Alert
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAuth } from '../../contexts/AuthContext/AuthContext.jsx';

import theme from '../../styles/theme.js';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { login } = useAuth();

    const isDevelopment = process.env.NODE_ENV === 'development';
    const organizationId = isDevelopment ? 'global-dev' : 'global-prod';

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await Axios.post('/api/v1/authentication/authenticate', { email, password, tenantId: organizationId });
            login(response.data.token);
            setLoading(false);
        } catch (err) {
            setError('Failed to authenticate. Please check your credentials and try again.');
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth={false} sx={{
                minHeight: '100vh',
                background: 'linear-gradient(180deg, #4eb855 0%, #a4d96c 100%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                margin: 0
            }}>
                <CssBaseline />
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: 400,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: 2,
                        padding: 3,
                        boxShadow: 3,
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                    </Box>
                    <Typography component="h1" variant="h5">
                        S.U.I.T Login
                    </Typography>
                    <Card sx={{ mt: 2, mb: 2, width: '100%' }}>
                        <CardContent>
                            <Typography variant="body1" component="p">
                                Welcome to the Ridecell Solutions Utility Interface Tool. Please enter your credentials to access the system.
                            </Typography>
                        </CardContent>
                    </Card>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address/Username"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={loading}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={loading}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            disabled={loading}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
                            {isDevelopment ? (
                                'Please note: For development, use the https://global.us.master.web-app.ridecell.io/admin-center account.'
                            ) : (
                                'Please note: For production, use either your US or EU production admin center accounts, region selection is handled automatically.'
                            )}
                        </Typography>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Sign In'}
                        </Button>
                        <Typography>
                            © 2024 Ridecell. All rights reserved.
                        </Typography>
                    </Box>
                    <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError('')}>
                        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Login;
